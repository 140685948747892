// @media (prefers-color-scheme: dark) {
//   .markdown,
//   [data-theme='dark'] {
//     /*dark*/
//     color-scheme: dark;
//     --color-prettylights-syntax-comment: #8b949e;
//     --color-prettylights-syntax-constant: #79c0ff;
//     --color-prettylights-syntax-entity: #d2a8ff;
//     --color-prettylights-syntax-storage-modifier-import: #c9d1d9;
//     --color-prettylights-syntax-entity-tag: #7ee787;
//     --color-prettylights-syntax-keyword: #ff7b72;
//     --color-prettylights-syntax-string: #a5d6ff;
//     --color-prettylights-syntax-variable: #ffa657;
//     --color-prettylights-syntax-brackethighlighter-unmatched: #f85149;
//     --color-prettylights-syntax-invalid-illegal-text: #f0f6fc;
//     --color-prettylights-syntax-invalid-illegal-bg: #8e1519;
//     --color-prettylights-syntax-carriage-return-text: #f0f6fc;
//     --color-prettylights-syntax-carriage-return-bg: #b62324;
//     --color-prettylights-syntax-string-regexp: #7ee787;
//     --color-prettylights-syntax-markup-list: #f2cc60;
//     --color-prettylights-syntax-markup-heading: #1f6feb;
//     --color-prettylights-syntax-markup-italic: #c9d1d9;
//     --color-prettylights-syntax-markup-bold: #c9d1d9;
//     --color-prettylights-syntax-markup-deleted-text: #ffdcd7;
//     --color-prettylights-syntax-markup-deleted-bg: #67060c;
//     --color-prettylights-syntax-markup-inserted-text: #aff5b4;
//     --color-prettylights-syntax-markup-inserted-bg: #033a16;
//     --color-prettylights-syntax-markup-changed-text: #ffdfb6;
//     --color-prettylights-syntax-markup-changed-bg: #5a1e02;
//     --color-prettylights-syntax-markup-ignored-text: #c9d1d9;
//     --color-prettylights-syntax-markup-ignored-bg: #1158c7;
//     --color-prettylights-syntax-meta-diff-range: #d2a8ff;
//     --color-prettylights-syntax-brackethighlighter-angle: #8b949e;
//     --color-prettylights-syntax-sublimelinter-gutter-mark: #484f58;
//     --color-prettylights-syntax-constant-other-reference-link: #a5d6ff;
//     --color-fg-default: #e6edf3;
//     --color-fg-muted: #848d97;
//     --color-fg-subtle: #6e7681;
//     --color-canvas-default: #0d1117;
//     --color-canvas-subtle: #161b22;
//     --color-border-default: #30363d;
//     --color-border-muted: #21262d;
//     --color-neutral-muted: rgba(110, 118, 129, 0.4);
//     --color-accent-fg: #2f81f7;
//     --color-accent-emphasis: #1f6feb;
//     --color-success-fg: #3fb950;
//     --color-success-emphasis: #238636;
//     --color-attention-fg: #d29922;
//     --color-attention-emphasis: #9e6a03;
//     --color-attention-subtle: rgba(187, 128, 9, 0.15);
//     --color-danger-fg: #f85149;
//     --color-danger-emphasis: #da3633;
//     --color-done-fg: #a371f7;
//     --color-done-emphasis: #8957e5;
//   }
// }

// @media (prefers-color-scheme: light) {
.markdown {
  /*light*/
  color-scheme: light;
  --color-prettylights-syntax-comment: #57606a;
  --color-prettylights-syntax-constant: #0550ae;
  --color-prettylights-syntax-entity: #6639ba;
  --color-prettylights-syntax-storage-modifier-import: #24292f;
  --color-prettylights-syntax-entity-tag: #116329;
  --color-prettylights-syntax-keyword: #cf222e;
  --color-prettylights-syntax-string: #0a3069;
  --color-prettylights-syntax-variable: #953800;
  --color-prettylights-syntax-brackethighlighter-unmatched: #82071e;
  --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
  --color-prettylights-syntax-invalid-illegal-bg: #82071e;
  --color-prettylights-syntax-carriage-return-text: #f6f8fa;
  --color-prettylights-syntax-carriage-return-bg: #cf222e;
  --color-prettylights-syntax-string-regexp: #116329;
  --color-prettylights-syntax-markup-list: #3b2300;
  --color-prettylights-syntax-markup-heading: #0550ae;
  --color-prettylights-syntax-markup-italic: #24292f;
  --color-prettylights-syntax-markup-bold: #24292f;
  --color-prettylights-syntax-markup-deleted-text: #82071e;
  --color-prettylights-syntax-markup-deleted-bg: #ffebe9;
  --color-prettylights-syntax-markup-inserted-text: #116329;
  --color-prettylights-syntax-markup-inserted-bg: #dafbe1;
  --color-prettylights-syntax-markup-changed-text: #953800;
  --color-prettylights-syntax-markup-changed-bg: #ffd8b5;
  --color-prettylights-syntax-markup-ignored-text: #eaeef2;
  --color-prettylights-syntax-markup-ignored-bg: #0550ae;
  --color-prettylights-syntax-meta-diff-range: #8250df;
  --color-prettylights-syntax-brackethighlighter-angle: #57606a;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #8c959f;
  --color-prettylights-syntax-constant-other-reference-link: #0a3069;
  --color-fg-default: #1f2328;
  --color-fg-muted: #656d76;
  --color-fg-subtle: #6e7781;
  --color-canvas-default: #ffffff;
  --color-canvas-subtle: #f6f8fa;
  --color-border-default: #d0d7de;
  --color-border-muted: hsla(210, 18%, 87%, 1);
  --color-neutral-muted: rgba(175, 184, 193, 0.2);
  --color-accent-fg: #0969da;
  --color-accent-emphasis: #0969da;
  --color-success-fg: #1a7f37;
  --color-success-emphasis: #1f883d;
  --color-attention-fg: #9a6700;
  --color-attention-emphasis: #9a6700;
  --color-attention-subtle: #fff8c5;
  --color-danger-fg: #d1242f;
  --color-danger-emphasis: #cf222e;
  --color-done-fg: #8250df;
  --color-done-emphasis: #8250df;
}
// }

.markdown {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  color: var(--color-fg-default);
  background-color: var(--color-canvas-default);
  font-size: 16px;
  line-height: 1.75;
  word-wrap: break-word;
  font-family: var(--URW-Din-Medium), system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
}

.markdown .octicon {
  display: inline-block;
  fill: currentColor;
  vertical-align: text-bottom;
}

.markdown h1:hover .anchor .octicon-link:before,
.markdown h2:hover .anchor .octicon-link:before,
.markdown h3:hover .anchor .octicon-link:before,
.markdown h4:hover .anchor .octicon-link:before,
.markdown h5:hover .anchor .octicon-link:before,
.markdown h6:hover .anchor .octicon-link:before {
  width: 16px;
  height: 16px;
  content: ' ';
  display: inline-block;
  background-color: currentColor;
  -webkit-mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' aria-hidden='true'><path fill-rule='evenodd' d='M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z'></path></svg>");
  mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' aria-hidden='true'><path fill-rule='evenodd' d='M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z'></path></svg>");
}

.markdown details,
.markdown figcaption,
.markdown figure {
  display: block;
}

.markdown summary {
  display: list-item;
}

.markdown [hidden] {
  display: none !important;
}

.markdown a {
  background-color: transparent;
  color: var(--color-accent-fg);
  text-decoration: none;
}

.markdown abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

.markdown b,
.markdown strong {
  font-weight: var(--base-text-weight-semibold, 600);
}

.markdown dfn {
  font-style: italic;
}

.markdown h1 {
  margin: 0.67em 0;
  font-weight: var(--base-text-weight-semibold, 600);
  padding-bottom: 0.3em;
  font-size: 2em;
  border-bottom: 1px solid var(--color-border-muted);
}

.markdown mark {
  background-color: var(--color-attention-subtle);
  color: var(--color-fg-default);
}

.markdown small {
  font-size: 90%;
}

.markdown sub,
.markdown sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.markdown sub {
  bottom: -0.25em;
}

.markdown sup {
  top: -0.5em;
}

.markdown img {
  border-style: none;
  max-width: 100%;
  box-sizing: content-box;
  background-color: var(--color-canvas-default);
  height: 100%;
  width: 100%;
  border-radius: 8px;
  max-height: 520px;
}

.markdown code,
.markdown kbd,
.markdown pre,
.markdown samp {
  font-family: monospace;
  font-size: 1em;
}

.markdown figure {
  margin: 1em 40px;
}

.markdown hr {
  box-sizing: content-box;
  overflow: hidden;
  background: transparent;
  border-bottom: 1px solid var(--color-border-muted);
  height: 0.25em;
  padding: 0;
  margin: 24px 0;
  background-color: var(--color-border-default);
  border: 0;
}

.markdown input {
  font: inherit;
  margin: 0;
  overflow: visible;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.markdown [type='button'],
.markdown [type='reset'],
.markdown [type='submit'] {
  -webkit-appearance: button;
  appearance: button;
}

.markdown [type='checkbox'],
.markdown [type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

.markdown [type='number']::-webkit-inner-spin-button,
.markdown [type='number']::-webkit-outer-spin-button {
  height: auto;
}

.markdown [type='search']::-webkit-search-cancel-button,
.markdown [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

.markdown ::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

.markdown ::-webkit-file-upload-button {
  -webkit-appearance: button;
  appearance: button;
  font: inherit;
}

.markdown a:hover {
  text-decoration: underline;
}

.markdown ::placeholder {
  color: var(--color-fg-subtle);
  opacity: 1;
}

.markdown hr::before {
  display: table;
  content: '';
}

.markdown hr::after {
  display: table;
  clear: both;
  content: '';
}

.markdown table {
  border-spacing: 0;
  border-collapse: collapse;
  display: block;
  width: max-content;
  max-width: 100%;
  overflow: auto;
}

.markdown td,
.markdown th {
  padding: 0;
}

.markdown details summary {
  cursor: pointer;
}

.markdown details:not([open]) > *:not(summary) {
  display: none !important;
}

.markdown a:focus,
.markdown [role='button']:focus,
.markdown input[type='radio']:focus,
.markdown input[type='checkbox']:focus {
  outline: 2px solid var(--color-accent-fg);
  outline-offset: -2px;
  box-shadow: none;
}

.markdown a:focus:not(:focus-visible),
.markdown [role='button']:focus:not(:focus-visible),
.markdown input[type='radio']:focus:not(:focus-visible),
.markdown input[type='checkbox']:focus:not(:focus-visible) {
  outline: solid 1px transparent;
}

.markdown a:focus-visible,
.markdown [role='button']:focus-visible,
.markdown input[type='radio']:focus-visible,
.markdown input[type='checkbox']:focus-visible {
  outline: 2px solid var(--color-accent-fg);
  outline-offset: -2px;
  box-shadow: none;
}

.markdown a:not([class]):focus,
.markdown a:not([class]):focus-visible,
.markdown input[type='radio']:focus,
.markdown input[type='radio']:focus-visible,
.markdown input[type='checkbox']:focus,
.markdown input[type='checkbox']:focus-visible {
  outline-offset: 0;
}

.markdown kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
    Liberation Mono, monospace;
  line-height: 10px;
  color: var(--color-fg-default);
  vertical-align: middle;
  background-color: var(--color-canvas-subtle);
  border: solid 1px var(--color-neutral-muted);
  border-bottom-color: var(--color-neutral-muted);
  border-radius: 6px;
  box-shadow: inset 0 -1px 0 var(--color-neutral-muted);
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: var(--base-text-weight-semibold, 600);
  line-height: 1.25;
}

.markdown h2 {
  font-weight: var(--base-text-weight-semibold, 600);
  padding-bottom: 0.3em;
  font-size: 1.5em;
  border-bottom: 1px solid var(--color-border-muted);
}

.markdown h3 {
  font-weight: var(--base-text-weight-semibold, 600);
  font-size: 1.25em;
}

.markdown h4 {
  font-weight: var(--base-text-weight-semibold, 600);
  font-size: 1em;
}

.markdown h5 {
  font-weight: var(--base-text-weight-semibold, 600);
  font-size: 0.875em;
}

.markdown h6 {
  font-weight: var(--base-text-weight-semibold, 600);
  font-size: 0.85em;
  color: var(--color-fg-muted);
}

.markdown p {
  margin-top: 0;
  margin-bottom: 10px;
}

.markdown blockquote {
  margin: 0;
  padding: 0 1em;
  color: var(--color-fg-muted);
  border-left: 0.25em solid var(--color-border-default);
}

.markdown ul,
.markdown ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2em;
}

.markdown ol ol,
.markdown ul ol {
  // list-style-type: lower-roman;
}

.markdown ul ul ol,
.markdown ul ol ol,
.markdown ol ul ol,
.markdown ol ol ol {
  // list-style-type: lower-alpha;
}

.markdown dd {
  margin-left: 0;
}

.markdown tt,
.markdown code,
.markdown samp {
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
    Liberation Mono, monospace;
  font-size: 12px;
}

.markdown pre {
  margin-top: 0;
  margin-bottom: 0;
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
    Liberation Mono, monospace;
  font-size: 12px;
  word-wrap: normal;
}

.markdown .octicon {
  display: inline-block;
  overflow: visible !important;
  vertical-align: text-bottom;
  fill: currentColor;
}

.markdown input::-webkit-outer-spin-button,
.markdown input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
}

.markdown .mr-2 {
  margin-right: var(--base-size-8, 8px) !important;
}

.markdown::before {
  display: table;
  content: '';
}

.markdown::after {
  display: table;
  clear: both;
  content: '';
}

.markdown > *:first-child {
  margin-top: 0 !important;
}

.markdown > *:last-child {
  margin-bottom: 0 !important;
}

.markdown a:not([href]) {
  color: inherit;
  text-decoration: none;
}

.markdown .absent {
  color: var(--color-danger-fg);
}

.markdown .anchor {
  float: left;
  padding-right: 4px;
  margin-left: -20px;
  line-height: 1;
}

.markdown .anchor:focus {
  outline: none;
}

.markdown p,
.markdown blockquote,
.markdown ul,
.markdown ol,
.markdown dl,
.markdown table,
.markdown pre,
.markdown details {
  margin-top: 0;
  margin-bottom: 16px;
}

.markdown blockquote > :first-child {
  margin-top: 0;
}

.markdown blockquote > :last-child {
  margin-bottom: 0;
}

.markdown h1 .octicon-link,
.markdown h2 .octicon-link,
.markdown h3 .octicon-link,
.markdown h4 .octicon-link,
.markdown h5 .octicon-link,
.markdown h6 .octicon-link {
  color: var(--color-fg-default);
  vertical-align: middle;
  visibility: hidden;
}

.markdown h1:hover .anchor,
.markdown h2:hover .anchor,
.markdown h3:hover .anchor,
.markdown h4:hover .anchor,
.markdown h5:hover .anchor,
.markdown h6:hover .anchor {
  text-decoration: none;
}

.markdown h1:hover .anchor .octicon-link,
.markdown h2:hover .anchor .octicon-link,
.markdown h3:hover .anchor .octicon-link,
.markdown h4:hover .anchor .octicon-link,
.markdown h5:hover .anchor .octicon-link,
.markdown h6:hover .anchor .octicon-link {
  visibility: visible;
}

.markdown h1 tt,
.markdown h1 code,
.markdown h2 tt,
.markdown h2 code,
.markdown h3 tt,
.markdown h3 code,
.markdown h4 tt,
.markdown h4 code,
.markdown h5 tt,
.markdown h5 code,
.markdown h6 tt,
.markdown h6 code {
  padding: 0 0.2em;
  font-size: inherit;
}

.markdown summary h1,
.markdown summary h2,
.markdown summary h3,
.markdown summary h4,
.markdown summary h5,
.markdown summary h6 {
  display: inline-block;
}

.markdown summary h1 .anchor,
.markdown summary h2 .anchor,
.markdown summary h3 .anchor,
.markdown summary h4 .anchor,
.markdown summary h5 .anchor,
.markdown summary h6 .anchor {
  margin-left: -40px;
}

.markdown summary h1,
.markdown summary h2 {
  padding-bottom: 0;
  border-bottom: 0;
}

.markdown ul.no-list,
.markdown ol.no-list {
  padding: 0;
  list-style-type: none;
}

.markdown ol[type='a s'] {
  list-style-type: lower-alpha;
}

.markdown ol[type='A s'] {
  list-style-type: upper-alpha;
}

.markdown ol[type='i s'] {
  list-style-type: lower-roman;
}

.markdown ol[type='I s'] {
  list-style-type: upper-roman;
}

.markdown ol[type='1'] {
  list-style-type: decimal;
}

.markdown div > ol:not([type]) {
  list-style-type: decimal;
}

.markdown ul ul,
.markdown ul ol,
.markdown ol ol,
.markdown ol ul {
  margin-top: 0;
  margin-bottom: 0;
}

.markdown li > p {
  margin-top: 16px;
}

.markdown li + li {
  margin-top: 0.25em;
}

.markdown dl {
  padding: 0;
}

.markdown dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: var(--base-text-weight-semibold, 600);
}

.markdown dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}

.markdown table th {
  font-weight: var(--base-text-weight-semibold, 600);
}

.markdown table th,
.markdown table td {
  padding: 6px 13px;
  border: 1px solid var(--color-border-default);
}

.markdown table td > :last-child {
  margin-bottom: 0;
}

.markdown table tr {
  background-color: var(--color-canvas-default);
  border-top: 1px solid var(--color-border-muted);
}

.markdown table tr:nth-child(2n) {
  background-color: var(--color-canvas-subtle);
}

.markdown table img {
  background-color: transparent;
}

.markdown img[align='right'] {
  padding-left: 20px;
}

.markdown img[align='left'] {
  padding-right: 20px;
}

.markdown .emoji {
  max-width: none;
  vertical-align: text-top;
  background-color: transparent;
}

.markdown span.frame {
  display: block;
  overflow: hidden;
}

.markdown span.frame > span {
  display: block;
  float: left;
  width: auto;
  padding: 7px;
  margin: 13px 0 0;
  overflow: hidden;
  border: 1px solid var(--color-border-default);
}

.markdown span.frame span img {
  display: block;
  float: left;
}

.markdown span.frame span span {
  display: block;
  padding: 5px 0 0;
  clear: both;
  color: var(--color-fg-default);
}

.markdown span.align-center {
  display: block;
  overflow: hidden;
  clear: both;
}

.markdown span.align-center > span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: center;
}

.markdown span.align-center span img {
  margin: 0 auto;
  text-align: center;
}

.markdown span.align-right {
  display: block;
  overflow: hidden;
  clear: both;
}

.markdown span.align-right > span {
  display: block;
  margin: 13px 0 0;
  overflow: hidden;
  text-align: right;
}

.markdown span.align-right span img {
  margin: 0;
  text-align: right;
}

.markdown span.float-left {
  display: block;
  float: left;
  margin-right: 13px;
  overflow: hidden;
}

.markdown span.float-left span {
  margin: 13px 0 0;
}

.markdown span.float-right {
  display: block;
  float: right;
  margin-left: 13px;
  overflow: hidden;
}

.markdown span.float-right > span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: right;
}

.markdown code,
.markdown tt {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  white-space: break-spaces;
  background-color: var(--color-neutral-muted);
  border-radius: 6px;
}

.markdown code br,
.markdown tt br {
  display: none;
}

.markdown del code {
  text-decoration: inherit;
}

.markdown samp {
  font-size: 85%;
}

.markdown pre code {
  font-size: 100%;
}

.markdown pre > code {
  padding: 0;
  margin: 0;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0;
}

.markdown .highlight {
  margin-bottom: 16px;
}

.markdown .highlight pre {
  margin-bottom: 0;
  word-break: normal;
}

.markdown .highlight pre,
.markdown pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  color: var(--color-fg-default);
  background-color: var(--color-canvas-subtle);
  border-radius: 6px;
}

.markdown pre code,
.markdown pre tt {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: transparent;
  border: 0;
}

.markdown .csv-data td,
.markdown .csv-data th {
  padding: 5px;
  overflow: hidden;
  font-size: 12px;
  line-height: 1;
  text-align: left;
  white-space: nowrap;
}

.markdown .csv-data .blob-num {
  padding: 10px 8px 9px;
  text-align: right;
  background: var(--color-canvas-default);
  border: 0;
}

.markdown .csv-data tr {
  border-top: 0;
}

.markdown .csv-data th {
  font-weight: var(--base-text-weight-semibold, 600);
  background: var(--color-canvas-subtle);
  border-top: 0;
}

.markdown [data-footnote-ref]::before {
  content: '[';
}

.markdown [data-footnote-ref]::after {
  content: ']';
}

.markdown .footnotes {
  font-size: 12px;
  color: var(--color-fg-muted);
  border-top: 1px solid var(--color-border-default);
}

.markdown .footnotes ol {
  padding-left: 16px;
}

.markdown .footnotes ol ul {
  display: inline-block;
  padding-left: 16px;
  margin-top: 16px;
}

.markdown .footnotes li {
  position: relative;
}

.markdown .footnotes li:target::before {
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -24px;
  pointer-events: none;
  content: '';
  border: 2px solid var(--color-accent-emphasis);
  border-radius: 6px;
}

.markdown .footnotes li:target {
  color: var(--color-fg-default);
}

.markdown .footnotes .data-footnote-backref g-emoji {
  font-family: monospace;
}

.markdown .pl-c {
  color: var(--color-prettylights-syntax-comment);
}

.markdown .pl-c1,
.markdown .pl-s .pl-v {
  color: var(--color-prettylights-syntax-constant);
}

.markdown .pl-e,
.markdown .pl-en {
  color: var(--color-prettylights-syntax-entity);
}

.markdown .pl-smi,
.markdown .pl-s .pl-s1 {
  color: var(--color-prettylights-syntax-storage-modifier-import);
}

.markdown .pl-ent {
  color: var(--color-prettylights-syntax-entity-tag);
}

.markdown .pl-k {
  color: var(--color-prettylights-syntax-keyword);
}

.markdown .pl-s,
.markdown .pl-pds,
.markdown .pl-s .pl-pse .pl-s1,
.markdown .pl-sr,
.markdown .pl-sr .pl-cce,
.markdown .pl-sr .pl-sre,
.markdown .pl-sr .pl-sra {
  color: var(--color-prettylights-syntax-string);
}

.markdown .pl-v,
.markdown .pl-smw {
  color: var(--color-prettylights-syntax-variable);
}

.markdown .pl-bu {
  color: var(--color-prettylights-syntax-brackethighlighter-unmatched);
}

.markdown .pl-ii {
  color: var(--color-prettylights-syntax-invalid-illegal-text);
  background-color: var(--color-prettylights-syntax-invalid-illegal-bg);
}

.markdown .pl-c2 {
  color: var(--color-prettylights-syntax-carriage-return-text);
  background-color: var(--color-prettylights-syntax-carriage-return-bg);
}

.markdown .pl-sr .pl-cce {
  font-weight: bold;
  color: var(--color-prettylights-syntax-string-regexp);
}

.markdown .pl-ml {
  color: var(--color-prettylights-syntax-markup-list);
}

.markdown .pl-mh,
.markdown .pl-mh .pl-en,
.markdown .pl-ms {
  font-weight: bold;
  color: var(--color-prettylights-syntax-markup-heading);
}

.markdown .pl-mi {
  font-style: italic;
  color: var(--color-prettylights-syntax-markup-italic);
}

.markdown .pl-mb {
  font-weight: bold;
  color: var(--color-prettylights-syntax-markup-bold);
}

.markdown .pl-md {
  color: var(--color-prettylights-syntax-markup-deleted-text);
  background-color: var(--color-prettylights-syntax-markup-deleted-bg);
}

.markdown .pl-mi1 {
  color: var(--color-prettylights-syntax-markup-inserted-text);
  background-color: var(--color-prettylights-syntax-markup-inserted-bg);
}

.markdown .pl-mc {
  color: var(--color-prettylights-syntax-markup-changed-text);
  background-color: var(--color-prettylights-syntax-markup-changed-bg);
}

.markdown .pl-mi2 {
  color: var(--color-prettylights-syntax-markup-ignored-text);
  background-color: var(--color-prettylights-syntax-markup-ignored-bg);
}

.markdown .pl-mdr {
  font-weight: bold;
  color: var(--color-prettylights-syntax-meta-diff-range);
}

.markdown .pl-ba {
  color: var(--color-prettylights-syntax-brackethighlighter-angle);
}

.markdown .pl-sg {
  color: var(--color-prettylights-syntax-sublimelinter-gutter-mark);
}

.markdown .pl-corl {
  text-decoration: underline;
  color: var(--color-prettylights-syntax-constant-other-reference-link);
}

.markdown g-emoji {
  display: inline-block;
  min-width: 1ch;
  font-family: 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1em;
  font-style: normal !important;
  font-weight: var(--base-text-weight-normal, 400);
  line-height: 1;
  vertical-align: -0.075em;
}

.markdown g-emoji img {
  width: 1em;
  height: 1em;
}

.markdown .task-list-item {
  list-style-type: none;
}

.markdown .task-list-item label {
  font-weight: var(--base-text-weight-normal, 400);
}

.markdown .task-list-item.enabled label {
  cursor: pointer;
}

.markdown .task-list-item + .task-list-item {
  margin-top: 4px;
}

.markdown .task-list-item .handle {
  display: none;
}

.markdown .task-list-item-checkbox {
  margin: 0 0.2em 0.25em -1.4em;
  vertical-align: middle;
}

.markdown .contains-task-list:dir(rtl) .task-list-item-checkbox {
  margin: 0 -1.6em 0.25em 0.2em;
}

.markdown .contains-task-list {
  position: relative;
}

.markdown .contains-task-list:hover .task-list-item-convert-container,
.markdown .contains-task-list:focus-within .task-list-item-convert-container {
  display: block;
  width: auto;
  height: 24px;
  overflow: visible;
  clip: auto;
}

.markdown ::-webkit-calendar-picker-indicator {
  filter: invert(50%);
}

.markdown .markdown-alert {
  padding: var(--base-size-8) var(--base-size-16);
  margin-bottom: 16px;
  color: inherit;
  border-left: 0.25em solid var(--color-border-default);
}

.markdown .markdown-alert > :first-child {
  margin-top: 0;
}

.markdown .markdown-alert > :last-child {
  margin-bottom: 0;
}

.markdown .markdown-alert .markdown-alert-title {
  display: flex;
  font-weight: var(--base-text-weight-medium, 500);
  align-items: center;
  line-height: 1;
}

.markdown .markdown-alert.markdown-alert-note {
  border-left-color: var(--color-accent-emphasis);
}

.markdown .markdown-alert.markdown-alert-note .markdown-alert-title {
  color: var(--color-accent-fg);
}

.markdown .markdown-alert.markdown-alert-important {
  border-left-color: var(--color-done-emphasis);
}

.markdown .markdown-alert.markdown-alert-important .markdown-alert-title {
  color: var(--color-done-fg);
}

.markdown .markdown-alert.markdown-alert-warning {
  border-left-color: var(--color-attention-emphasis);
}

.markdown .markdown-alert.markdown-alert-warning .markdown-alert-title {
  color: var(--color-attention-fg);
}

.markdown .markdown-alert.markdown-alert-tip {
  border-left-color: var(--color-success-emphasis);
}

.markdown .markdown-alert.markdown-alert-tip .markdown-alert-title {
  color: var(--color-success-fg);
}

.markdown .markdown-alert.markdown-alert-caution {
  border-left-color: var(--color-danger-emphasis);
}

.markdown .markdown-alert.markdown-alert-caution .markdown-alert-title {
  color: var(--color-danger-fg);
}
