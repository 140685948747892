/* Landing Page Animated Design */

.ui-container {
  background-image: url('/banner/banner-landing-page.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  @apply min-h-96 sm:min-h-[800px];
}
.ui-layer-1 {
  height: 380px;
  width: 380px;
  object-fit: contain;
  position: absolute;
  right: -20px;
  top: 10px;
  z-index: 9;
  @apply hidden sm:flex;
}
.ui-layer-2 {
  object-fit: contain;
  position: absolute;
  @apply object-contain absolute h-32 w-32 sm:h-48 sm:w-48 sm:left-[20%] sm:top-[25%] left-8 -top-2;
}
.ui-layer-3 {
  height: 200px;
  width: 200px;
  object-fit: contain;
  position: absolute;
  right: 15%;
  top: 5%;
  @apply object-contain absolute h-32 w-32 sm:h-48 sm:w-48 sm:right-[15%] sm:top-[5%] right-0 -top-2;
}
.ui-layer-4 {
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 0px;
  transform: rotate(180deg);
  opacity: 0.7;
  @apply w-20 sm:w-auto max-h-80 sm:max-h-none;
}
.text-layer-1 {
  height: 100%;
  position: absolute;
  // top: 32%;
  // left: -14%;
  font-weight: bold;
  line-height: normal;
  font-family: var(--urw-din-medium), system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  @apply text-[20rem] sm:text-[40rem] -left-12 top-0 sm:-top-16 sm:-left-[14%];
}
.text-layer-2 {
  position: absolute;
  display: block;
  overflow: hidden;
  z-index: 9;
  @apply -translate-x-[10%] top-[25%] left-[50%] sm:left-[58%] sm:top-[24%] sm:-translate-x-1/2;

  h2 {
    font-size: 6rem;
    line-height: 120px;
    font-family: var(--urw-din-medium), system-ui, -apple-system,
      BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
      'Open Sans', 'Helvetica Neue', sans-serif;
    @apply font-bold text-4xl sm:text-7xl md:text-8xl;
  }
  p {
    @apply mt-2 text-lg sm:text-xl text-default-700;
  }
}
