/*
* STYLE FOR CARDS
*/
.preview-image {
  .layer {
    transition: all 250ms ease-in-out;
    opacity: 0;
  }

  &:hover .layer {
    opacity: 1;
  }
}

.footer-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),
    url('/banner/dark-forest.webp');

  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.dark-leaf-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('/banner/leaf-background.webp');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.dark-forest-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url('https://images.unsplash.com/photo-1495476479092-6ece1898a101?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.our-projects-background {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.85),
      rgba(0, 0, 0, 0.85)
    ),
    url('https://images.unsplash.com/photo-1491146179969-d674118945ff?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @apply pb-8 sm:pb-16;
}

.green-leafed-plant-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('https://images.unsplash.com/photo-1612422300295-5f5a5777aa0e?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: 100% 100%;
}

.five-potted-succulent-plants-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('https://i.pinimg.com/originals/44/6e/3b/446e3b79395a287ca32f7977dd83b290.jpg');
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: 100% 100%;
}

.nature-plant-flower-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('https://images.unsplash.com/photo-1571235854001-2c64e3fdd06a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  @apply pb-8 sm:pb-16;
}
