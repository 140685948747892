@tailwind base;
@tailwind components;
@tailwind utilities;

@import './cards.scss';
@import './animated.scss';
@import './markdown.scss';

:root {
  --app-bg-light: #96b5d1;
  --app-color-green: #365b7c;
  --app-color-main: #24313c;
}

::selection {
  @apply text-default-50 bg-[var(--app-color-green)];
}

.urw-din-medium {
  font-family: var(--URW-Din-Medium), system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
}

html,
body {
  font-family: var(--URW-Din-Medium), system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  line-height: 1.75;
  // background: #efefef;
  color: #24313c;
  color-scheme: light;
}
.animate-svg {
  &:hover svg {
    transition: all 300ms ease-in-out;
    transform: translateX(4px);
  }
}
.bg-basic {
  background-color: var(--app-color-green);
}
.primary-text {
  color: var(--app-color-green);
}
.section {
  @apply mx-auto max-w-3xl xl:max-w-6xl px-3;
}
.single-latest {
  @apply relative overflow-hidden;
  img {
    @apply w-full;
  }
  .overlay {
    @apply absolute text-center leading-none opacity-100 transition-all duration-300 ease-[ease] inset-0 bg-black/20 z-10;
    &:hover {
      @apply cursor-pointer bg-black/40 backdrop-blur-md;
    }
    .box-holder {
      @apply table w-full h-full relative;
      &:before,
      &:after {
        @apply content-[''] absolute opacity-0;
      }
      &:after {
        @apply skew-x-[-100deg] transition-all duration-[0.3s] ease-[ease] border-y-white border-t border-solid border-b inset-x-[15px] inset-y-[30px];
      }
      &:before {
        @apply transition-all duration-[0.3s] ease-[ease] delay-[0.3s] border-x-white border-l border-solid border-r top-full bottom-[15px] inset-x-[30px];
      }
      .content-box {
        @apply table-cell align-middle transition-all duration-[0.3s] ease-[ease] px-[50px];
        h3,
        .heading-text {
          @apply text-base lg:text-xl leading-6 capitalize font-medium text-default-50 transition-all duration-300 ease-[ease] opacity-0;
        }
        p {
          opacity: 0;
          height: 0;
          margin-top: 12px;
          transition: all 0.3s ease;
          @apply text-xs lg:text-base text-default-600;
        }
        button {
          opacity: 0;
        }
      }
    }
    &:hover {
      .box-holder:after {
        @apply opacity-100 skew-x-0;
      }
      .box-holder:before {
        @apply opacity-100 top-4;
      }
      .content-box {
        h3,
        .heading-text {
          @apply opacity-100;
        }
        p {
          opacity: 1;
          height: auto;
          z-index: 99;
        }
        button {
          opacity: 1;
        }
      }
    }
  }
}

// @keyframes movement {
//   0% {
//     transform: translateY(0);
//   }
//   50% {
//     transform: translateY(-16px);
//   }
//   100% {
//     transform: translateY(0);
//   }
// }

.hover-rotate:hover img {
  transform: rotate(0deg);
  transition: 1000ms;
}
.hover-rotate img {
  transform: rotate(-360deg);
  transition: 1000ms;
}

.button-transition .right,
.button-transition .left {
  transition: 250ms;
}
.button-transition:hover .right {
  transform: translateX(8px);
  transition: 250ms;
}
.button-transition:hover .left {
  transform: translateX(-8px);
  transition: 250ms;
}

.imageHoverCardParent:hover .imageHoverCard {
  @apply flex;
}

//project page card design start

:root {
  --cover-timing: 0.5s;
  --cover-ease: cubic-bezier(0.66, 0.08, 0.19, 0.97);
  --cover-stagger: 0.15s;

  --text-timing: 0.75s;
  --text-stagger: 0.015s;
  --text-ease: cubic-bezier(0.38, 0.26, 0.05, 1.07);

  --title-stagger: 0.05s;
  --highlight: white;
}

// h2 {
//   font-size: 30px;
//   font-size: clamp(20px, 4vw, 40px);
//   font-weight: 800;
//   margin-bottom: 0.2em;
// }

// p {
//   font-size: 12px;
//   font-size: clamp(10px, 1.25vw, 14px);
//   line-height: 1.4;
//   text-align: justify;
//   margin-top: 0.2em;
//   margin-bottom: 0;
// }

h2 .char,
p .word {
  color: var(--highlight);
  display: inline-block;
  opacity: 0;
  position: relative;
  transform: translateY(20px);
  transition-property: transform, opacity, color;
  transition-timing-function: var(--text-ease);
  transition-duration: var(--text-timing), var(--text-timing),
    calc(var(--text-timing) * 2);
}

//project page card design end
.contact-background {
  background-image: url('/images/contact-banner.jpg');
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: 100% 100%;
}
button[aria-label='Close'] {
  display: none;
}

#widgetButtonFrame {
  color-scheme: none;
  background: transparent;
}

.hover-news:hover {
  background: linear-gradient(13deg, black 40%, green);
  img {
    opacity: 0.5;
    transition: 600ms;
  }
}

.vimeo-container {
  iframe {
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.gradient-box-shadow {
  padding: 4px;
  position: relative;
  transform: translate(0);
  transform-style: preserve-3d;
  box-shadow: rgba(255, 255, 255, 0.3) 0 5vw 6vw -8vw,
    rgba(255, 255, 255, 0) 0 4.5vw 5vw -6vw,
    rgba(50, 50, 80, 0.5) 0px 4vw 8vw -2vw, rgba(0, 0, 0, 0.8) 0px 4vw 5vw -3vw;
  &:hover {
    box-shadow: white 0 5vw 6vw -9vw, var(--highlight) 0 5.5vw 5vw -7.5vw,
      rgba(50, 50, 80, 0.5) 0px 4vw 8vw -2vw,
      rgba(0, 0, 0, 0.8) 0px 4vw 5vw -3vw;
    color: white;
    transition: 1000ms;
  }
}

.neon-links {
  transition: width 0.4s;
  &::before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    height: 3px;
    width: 0;
    background: var(--app-color-green);
    z-index: -1;

    // box-shadow: 0 0 10px var(--app-color-green), 0 0 20px var(--app-color-green),
    //   0 0 32px var(--app-color-green), 0 0 80px var(--app-color-green),
    //   0 0 100px var(--app-color-green);
    transition: width 0.4s;
    // @apply rounded-lg;
  }

  &:hover::before {
    width: 100%;
  }
}

.neon-button {
  --color: var(--app-color-green);
  font-size: 1rem;
  color: var(--color);
  border: var(--color) 0.125em solid;
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor;
  box-shadow: inset 0 0 0.5em 0 var(--color), 0 0 0.5em 0 var(--color);
  position: relative;
  @apply text-base leading-4 tracking-[1px] px-8 py-[15px] border-solid rounded-lg;
}

.neon-button::before {
  pointer-events: none;
  content: '';
  position: absolute;
  background: var(--color);
  top: 120%;
  left: 0;
  width: 100%;
  height: 100%;

  transform: perspective(1em) rotateX(40deg) scale(1, 0.35);
  filter: blur(1em);
  opacity: 0.7;
}

.neon-button::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 2em 0.5em var(--color);
  opacity: 0;
  background-color: var(--color);
  z-index: -1;
  transition: opacity 100ms linear;
}

.neon-button:hover,
.neon-button:focus {
  color: var(--clr-bg);
  text-shadow: none;
  @apply text-default-50;
}

.neon-button:hover::before,
.neon-button:focus::before {
  opacity: 1;
}
.neon-button:hover::after,
.neon-button:focus::after {
  opacity: 1;
}

.skiptranslate {
  display: none;
}
